import classNames from 'classnames'
import dayjs from 'dayjs'
import Link from 'next/link'
import { GradeNotification } from 'types/user'

const GradeNotificationView = ({
  notification,
}: { notification: GradeNotification }) => {
  const assessment = notification.assessment
  const courseEdition = assessment.courseSegment.courseEdition
  const courseSlug = courseEdition?.course.slug
  const editionSlug = courseEdition?.slug
  const url = `/courses/${courseSlug}/${editionSlug}/assessment/${assessment.id}`

  return (
    <Link href={url}>
      <a>
        <div
          className={classNames(
            'px-4 py-4 text-sm flex space-x-2',
            notification.isRead ? 'bg-gray-50' : '',
          )}
        >
          <div className='flex-0 w-8'>
            <div className='inline-flex items-center'>
              <div className='text-white rounded-full inline-flex justify-center items-center h-8 w-8 bg-purple-heart'>
                <svg
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 50 51'
                  className='w-6 h-6'
                >
                  <path
                    d='M23.4 40.7h-7.3a4.2 4.2 0 01-4.1-4.2V14.6a4 4 0 014.1-4.1h13l9 8.8V24m-2.7 7.3v9.4m4.7-4.7h-9.4'
                    stroke='#fff'
                    strokeWidth='3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M37.5 19.8h-8.9V11'
                    stroke='#fff'
                    strokeWidth='3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              'flex-1 leading-snug',
              notification.isRead
                ? 'opacity-60 text-gray-500'
                : 'font-semibold',
            )}
          >
            Your grade for{' '}
            <span className='text-purple-heart'>{assessment.title}</span> has
            been released. View now.
          </div>
          <div className='flex-0 text-right w-6 text-gray-400'>
            {dayjs(notification.createdAt).locale('shortNames').fromNow()}
          </div>
        </div>
      </a>
    </Link>
  )
}

export default GradeNotificationView
