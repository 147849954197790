import classNames from 'classnames'
import { Fragment } from 'react'

export const menuTransition = {
  as: Fragment,
  enter: 'transition ease-out duration-100',
  enterFrom: 'transform opacity-0 scale-95',
  enterTo: 'transform opacity-100 scale-100',
  leave: 'transition ease-in duration-75',
  leaveFrom: 'transform opacity-100 scale-100',
  leaveTo: 'transform opacity-0 scale-95',
}

export function menuItemClasses(active: boolean) {
  return classNames(
    active ? 'bg-gray-100' : 'text-gray-700',
    'block px-4 py-2 text-sm w-full text-left cursor-pointer',
  )
}

export function menuItemClassesMobile(active: boolean) {
  return classNames(
    active ? 'text-purple-heart font-bold' : 'text-gray-500 font-medium',
    'block pl-3 pr-4 py-2 text-base hover:text-purple-heart cursor-pointer',
  )
}
