import { use } from 'chai'
import { useRouter } from 'next/router'
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react'

// Define the context type
interface SidebarContextProps {
  isSidebarOpen: boolean
  toggleSidebar: () => void
  closeSidebar: () => void
  openSidebar: () => void
}

// Create the context
const SidebarContext = createContext<SidebarContextProps | undefined>(undefined)

type SidebarProviderProps = {
  children: ReactNode
  hideSidebar?: boolean
}

// Provider component to wrap around your app
export const SidebarProvider = ({
  children,
  hideSidebar = false,
}: SidebarProviderProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [isDesktop, setIsDesktop] = useState(false)
  const [isHiddenByDefault, setIsHiddenByDefault] = useState(false)
  const router = useRouter()

  // Helper to verify desktop screen size
  const checkIsDesktop = () => window.innerWidth >= 768

  // Determine screen size on initial render
  useEffect(() => {
    const handleResize = () => {
      // Set the sidebar open by default for desktop (width > 768px), hidden for mobile
      if (checkIsDesktop()) {
        setIsDesktop(true) // Open by default on desktop

        // Detect passing breakpoint from mobile to desktop
        // Note if the page has the sidebar hidden, it will remain hidden on desktop
        if (!isDesktop && !hideSidebar) {
          setIsSidebarOpen(true)
        }
      } else {
        setIsDesktop(false) // Hidden by default on mobile

        // Detect passing breakpoint from desktop to mobile
        if (isDesktop) {
          setIsSidebarOpen(false)
        }
      }
    }

    // Run this when the component mounts
    handleResize()

    // Add a resize event listener to update the state when screen size changes
    window.addEventListener('resize', handleResize)

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize)
  }, [isDesktop])

  // This effect is triggered on page navigation. Some pages will have a
  // different value for hideSidebar, so we need to update the sidebar state
  useEffect(() => {
    // Record last state of hideSidebar for future state changes
    setIsHiddenByDefault(hideSidebar)

    // Always hide the sidebar on mobile when changing page
    if (!checkIsDesktop()) {
      setIsSidebarOpen(false)
      return
    }

    // When navigating from a page that has collapsed by default, to one that's expanded
    // by default, then open the menu
    if (isHiddenByDefault && !hideSidebar) {
      setIsSidebarOpen(true)
    }

    // When navigating to a page that was shown by default, to one that's collapsed, then close the menu
    if (!isHiddenByDefault && hideSidebar) {
      setIsSidebarOpen(false)
    }
  }, [router.asPath, hideSidebar, isHiddenByDefault])

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState)
  }

  const closeSidebar = () => {
    setIsSidebarOpen(false)
  }

  const openSidebar = () => {
    setIsSidebarOpen(true)
  }

  return (
    <SidebarContext.Provider
      value={{ isSidebarOpen, toggleSidebar, closeSidebar, openSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

// Custom hook to use the SidebarContext
export const useSidebar = () => {
  const context = useContext(SidebarContext)
  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider')
  }
  return context
}
