import FolderIcon from './svg/folder.svg'
import UniversityIcon from './svg/university.svg'

// Define the allowed icon names
export type IconName = 'folder' | 'university'

type IconProps = {
  name: IconName // Restrict name to specific keys
  className?: string
  size?: number
}

const icons: Record<
  IconName,
  React.ComponentType<React.SVGProps<SVGSVGElement>>
> = {
  folder: FolderIcon,
  university: UniversityIcon,
}

export const Icon = ({ name, className = '', size = 24 }: IconProps) => {
  const IconComponent = icons[name]

  if (!IconComponent) {
    return null
  }

  return <IconComponent className={className} width={size} height={size} />
}
