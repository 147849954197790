import { COURSE_PROVIDER } from 'gql/fragments/provider'
import gql from 'graphql-tag'

export const GET_COURSE_PROVIDERS = gql`
  query getCourseProviders {
    providers: getCourseProviders {
      ...courseProviderFields
    }
  }
  ${COURSE_PROVIDER}
`

export const GET_COURSE_PROVIDER = gql`
  query getCourseProvider($slug: String!) {
    provider: getCourseProvider(slug: $slug) {
      ...courseProviderFields
    }
  }
  ${COURSE_PROVIDER}
`
