import { useSession } from 'next-auth/client'
import Link from 'next/link'
import React from 'react'
import { PublicUser } from 'types/user'

const UserLink = ({
  user,
  children,
}: { user: PublicUser; children?: React.ReactNode }) => {
  const [session] = useSession()
  return (
    <Link href={`/user/${session?.user.id === user.id ? 'me' : user.id}`}>
      <a>{children}</a>
    </Link>
  )
}

export default UserLink
