import React from 'react'

type LoadingProps = {
  isLoading?: boolean
  className?: string
}

export const Loading = ({ isLoading = true, className }: LoadingProps) => {
  return isLoading ? (
    <div className={`w-100 text-center ${className}`}>
      <svg
        width='88'
        height='88'
        viewBox='-15 -10 58 48'
        xmlns='http://www.w3.org/2000/svg'
        stroke='#000000'
        style={{ display: 'block', margin: 'auto' }}
      >
        <g fill='none' fillRule='evenodd'>
          <g transform='translate(1 1)' strokeWidth='2'>
            <circle strokeOpacity='.5' cx='18' cy='18' r='18' />
            <path d='M36 18c0-9.94-8.06-18-18-18'>
              <animateTransform
                attributeName='transform'
                type='rotate'
                from='0 18 18'
                to='360 18 18'
                dur='1s'
                repeatCount='indefinite'
              />
            </path>
          </g>
        </g>
      </svg>
    </div>
  ) : (
    <></>
  )
}
