import Footer from 'components/Footer'
import Header from 'components/Header'
import { Sidebar } from 'components/Sidebar/Sidebar'
import { StepsRefsProvider } from 'context/StepsRefsProvider/StepsRefsProvider'
import React from 'react'
import { SidebarProvider } from '../../context/SidebarProvider/SidebarProvider'

type LayoutProps = {
  children: React.ReactNode | React.ReactNode[]
  className?: string
  showLogin?: boolean
  showFooter?: boolean
  hideSidebar?: boolean
}

/**
 * Use to specify only the header component of the layout
 *
 * @param children
 * @constructor
 */
const LayoutHeader = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>
}

/**
 * Use to specify only the body component of the layout
 *
 * @param children
 * @constructor
 */
const LayoutBody = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>
}

/**
 * Used to specify only the side menu component of the layout
 *
 * @param children
 * @constructor
 */
const LayoutMenu = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>
}

const Layout = ({
  children,
  className,
  showLogin = true,
  showFooter = false,
  hideSidebar = false,
}: LayoutProps) => {
  const childrenArray = React.Children.toArray(children)
  // Check if the children array has a LayoutBody and LayoutMenu component
  const body =
    childrenArray.find((child) => (child as any).type === LayoutBody) ||
    children
  const menu = childrenArray.find((child) => (child as any).type === LayoutMenu)
  const header = childrenArray.find(
    (child) => (child as any).type === LayoutHeader,
  )

  return (
    <SidebarProvider hideSidebar={hideSidebar}>
      <Sidebar alternateMenu={menu}>
        <StepsRefsProvider>
          <div
            className={`min-h-screen flex flex-col ${className}`}
            data-testid='layout'
          >
            <Header showLogin={showLogin} header={header} />
            <div className='mb-6 md:mb-10 flex-1'>{body}</div>
            {showFooter ? <Footer /> : null}
          </div>
        </StepsRefsProvider>
      </Sidebar>
    </SidebarProvider>
  )
}

Layout.Body = LayoutBody
Layout.Menu = LayoutMenu
Layout.Header = LayoutHeader

export default Layout
