// https://www.codegrepper.com/code-examples/whatever/converter+rgba+to+hex+without+opacity
const hexify = (color: string) => {
  const values = color
    .replace(/rgba?\(/, '')
    .replace(/\)/, '')
    .replace(/[\s+]/g, '')
    .split(',')
  const a = Number.parseFloat(values[3] || '1')
  const r = Math.floor(a * Number.parseInt(values[0]) + (1 - a) * 255)
  const g = Math.floor(a * Number.parseInt(values[1]) + (1 - a) * 255)
  const b = Math.floor(a * Number.parseInt(values[2]) + (1 - a) * 255)
  return `#${(`0${r.toString(16)}`).slice(-2)}${(`0${g.toString(16)}`).slice(-2)}${(`0${b.toString(16)}`).slice(-2)}`
}

export const TransparentizeHex = (col: string, amt: number) => {
  if (typeof col === 'undefined') {
    // Return the default lighter tech futures labs colour if provider has no colour set
    return '#e5f7ff'
  }

  if (col && col[0] === '#') {
    col = col.slice(1)
  }

  const num = Number.parseInt(col, 16)

  const r = num >> 16

  const g = (num >> 8) & 0x00ff

  const b = num & 0x0000ff

  return hexify(`rgba(${r}, ${g}, ${b}, ${amt})`)
}
