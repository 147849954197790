import { PUBLIC_USER } from 'gql/fragments/user'
import gql from 'graphql-tag'

export const GET_NOTIFICATIONS = gql`
  query getNotifications($pageSize: Int, $page: Int) {
    getNotifications(pageSize: $pageSize, page: $page) {
      paginatorInfo {
        count
        page
        pageSize
        hasMorePages
        total
        totalUnread
      }
      data {
        id
        isRead
        createdAt
        ... on UserCommentNotification {
          comment {
            id
            createdAt
            courseStep {
              id
              title
              reference
              courseSection {
                id
                courseSegment {
                  id
                  courseEdition {
                    id
                    slug
                    course {
                      id
                      slug
                      period
                    }
                  }
                }
              }
            }
            user {
              ...publicUserFields
            }
          }
        }
        ... on UserGradeNotification {
          assessment {
            id
            title
            courseSegment {
              id
              courseEdition {
                id
                slug
                course {
                  id
                  slug
                  period
                }
              }
            }
          }
        }
      }
    }
  }
  ${PUBLIC_USER}
`

export const POLL_NEW_NOTIFICATIONS = gql`
  query pollNotifications($pageSize: Int, $page: Int) {
    getNotifications(pageSize: $pageSize, page: $page) {
      paginatorInfo {
        totalUnread
      }
    }
  }
`

export const MARK_NOTIFICATION_READ = gql`
  mutation markNotificationRead($notificationId: ID!) {
    markNotificationRead(notificationId: $notificationId)
  }
`

export const MARK_ALL_NOTIFICATIONS_READ = gql`
  mutation markAllNotificationsRead {
    markAllNotificationsRead
  }
`
