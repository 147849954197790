import { useMutation, useQuery } from '@apollo/client'
import { Menu, Transition } from '@headlessui/react'
import { BellIcon, XIcon } from '@heroicons/react/outline'
import Button from 'components/Button'
import CommentNotificationView from 'components/CommentNotification/CommentNotification'
import GradeNotificationView from 'components/GradeNotification/GradeNotification'
import { Loading } from 'components/Loading'
import dayjs from 'dayjs'
import en from 'dayjs/locale/en'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import {
  GET_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_READ,
  MARK_NOTIFICATION_READ,
  POLL_NEW_NOTIFICATIONS,
} from 'gql/notifications'
import gql from 'graphql-tag'
import { useTranslation } from 'next-i18next'
import React, { useState, useEffect, useRef } from 'react'
import { menuTransition } from 'styles/menu'
import {
  CommentNotification,
  GradeNotification,
  Notification,
  PaginatorInfo,
} from 'types/user'

const POLLING_INTERVAL = 60000 * 3 //3 minutes
const PAGE_SIZE = 5

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

// Set shortnames locale
dayjs.locale('shortNames', {
  ...en,
  name: 'shortNames',
})

// Set default locale
dayjs.locale('en')

dayjs.updateLocale('shortNames', {
  relativeTime: {
    future: (str: string) => str,
    past: (str: string) => str,
    s: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mth',
    MM: '%dmth',
    y: '1y',
    yy: '%dy',
  },
})

function isCommentNotification(
  value: Notification,
): value is CommentNotification {
  return value.__typename === 'UserCommentNotification'
}
function isGradeNotification(value: Notification): value is GradeNotification {
  return value.__typename === 'UserGradeNotification'
}

const NotificationsMenu = () => {
  const { t } = useTranslation()
  const [numUnread, setNumUnread] = useState(-1) //always run the first time
  const contentRef = useRef<HTMLDivElement>(null)
  const [loadingMore, setLoadingMore] = useState(false)

  const { data: pollData } = useQuery<{
    pollNotifications: { paginatorInfo: PaginatorInfo }
  }>(POLL_NEW_NOTIFICATIONS, {
    pollInterval: POLLING_INTERVAL,
  })

  const { loading, data, error, fetchMore } = useQuery<{
    getNotifications: { paginatorInfo: PaginatorInfo; data: Notification[] }
  }>(GET_NOTIFICATIONS, {
    variables: {
      pageSize: PAGE_SIZE,
      page: 0,
    },
    skip: pollData?.pollNotifications?.paginatorInfo.totalUnread === numUnread,
    notifyOnNetworkStatusChange: true,
  })

  const [markNotificationRead] = useMutation(MARK_NOTIFICATION_READ, {
    onCompleted: () => {
      setNumUnread((numUnread) => numUnread - 1)
    },
  })

  const [markAllNotificationsRead] = useMutation(MARK_ALL_NOTIFICATIONS_READ, {
    onCompleted: () => {
      setNumUnread(0)
    },
  })

  const { data: notifications } = data?.getNotifications || {}
  const { hasMorePages, page = 0 } = data?.getNotifications.paginatorInfo || {}
  const { totalUnread = 0 } = data?.getNotifications.paginatorInfo || {}

  useEffect(() => {
    setNumUnread(totalUnread)
  }, [totalUnread])

  // scroll to bottom on load
  useEffect(() => {
    if (loadingMore) {
      setLoadingMore(false)
      if (contentRef.current) {
        contentRef.current.scrollTop = contentRef.current.scrollHeight
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, contentRef])

  if (error) {
    console.log(error, 'notifications error')
    return null
  }

  const loadMore = () => {
    setLoadingMore(true)
    fetchMore({
      variables: {
        page: page + 1,
      },
    })
  }

  const markAsRead = (notification: Notification) => {
    markNotificationRead({
      variables: {
        notificationId: notification.id,
      },
      optimisticResponse: {
        markNotificationRead: true,
      },
      update: (cache, data, { variables }) => {
        // update isRead status of notification in cache

        cache.writeFragment({
          id: `${notification.__typename}:${variables?.notificationId}`,
          fragment: gql`
            fragment Notification on ${notification.__typename} {
              isRead
            }
          `,
          data: {
            isRead: true,
          },
        })
      },
    })
  }

  const markAllAsRead = () => {
    markAllNotificationsRead({
      optimisticResponse: {
        markAllNotificationsRead: true,
      },
      update: (cache) => {
        // find all notifications in cache
        const serializedState = cache.extract()
        const typeNameItems = Object.values(serializedState).filter(
          (item: any) =>
            item.__typename === 'UserCommentNotification' ||
            item.__typename === 'UserGradeNotification',
        )

        // update isRead status of all notifications in cache
        typeNameItems.forEach((item: any) => {
          cache.writeFragment({
            id: `${item.__typename}:${item.id}`,
            fragment: gql`
              fragment Notification on ${item.__typename} {
                isRead
              }
            `,
            data: {
              isRead: true,
            },
          })
        })
      },
    })
  }

  return (
    <Menu as='div' className='sm:relative'>
      <Menu.Button className='p-2 relative'>
        <span className='sr-only'>View notifications</span>
        <BellIcon className='h-6 w-6' aria-hidden='true' />
        {numUnread > 0 && (
          <div className='absolute bottom-px -right-1 bg-purple-heart rounded-full w-5 h-5 text-white text-xs text-center pt-1'>
            {numUnread}
          </div>
        )}
      </Menu.Button>

      <Transition {...menuTransition}>
        <Menu.Items className='origin-top-right absolute z-40 left-4 sm:left-auto right-4 sm:-right-1 mt-2 sm:w-screen sm:max-w-sm rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='border-b border-gray-100 px-4 py-3 flex justify-between'>
            <h4 className='text-2xl font-bold font-heading'>
              {t('Notifications')}
            </h4>
            <Menu.Button>
              <XIcon className='w-6 h-6 text-gray-400' />
            </Menu.Button>
          </div>

          <div
            className='max-h-notifications-menu md:max-h-80 overflow-auto'
            ref={contentRef}
          >
            {notifications &&
              notifications?.length > 0 &&
              notifications.map((notification) => {
                return (
                  <Menu.Item key={notification.id}>
                    <div>
                      {/* need second div because Menu.Item overrides the clicks */}
                      <div
                        onClick={() => {
                          if (!notification.isRead) {
                            markAsRead(notification)
                          }
                        }}
                      >
                        {isCommentNotification(notification) && (
                          <CommentNotificationView
                            notification={notification}
                          />
                        )}

                        {isGradeNotification(notification) && (
                          <GradeNotificationView notification={notification} />
                        )}
                      </div>
                    </div>
                  </Menu.Item>
                )
              })}
            {loading && (
              <div className='text-center py-2'>
                <Loading />
              </div>
            )}

            {notifications && notifications.length === 0 && (
              <div className='p-3 text-center text-sm'>
                {t('You do not have any notifications')}
              </div>
            )}
          </div>

          {notifications && notifications.length > 0 && (
            <div className='text-center space-x-5 p-3 border-t border-gray-100'>
              {hasMorePages && (
                <Button onClick={loadMore} theme='tertiary'>
                  {t('Load more')}
                </Button>
              )}

              <Button onClick={markAllAsRead} theme='tertiary'>
                {t('Mark all as read')}
              </Button>
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default NotificationsMenu
