import pluralize from 'pluralize'
import { PublicUser } from 'types'
import { Currency, Enrolment, EnrolmentStatus } from 'types/enrolment'

export const formatBytes = (bytes: number, decimals = 2 as number) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

/**
 * Limit text to a max limit
 * @param text
 * @param length
 * @param overflow
 */
export const limitText = (text: string, length = 200, overflow = '...') => {
  if (text.length <= length) {
    return text
  }

  const limited = text.substring(0, length).trim()
  return `${limited}${overflow}`
}

/**
 * Display price in dollars
 */
export const formatPrice = (
  priceInCents: number,
  currency: Currency,
  zeroAsFree = false,
): string => {
  if (!priceInCents && zeroAsFree) {
    return 'FREE'
  }

  const uppercaseCurrency = currency.toUpperCase()
  const maximumFractionDigits = priceInCents % 100 === 0 ? 0 : 2
  const minimumFractionDigits = maximumFractionDigits // Ensure min < max

  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: uppercaseCurrency,
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(priceInCents / 100)

  // The en-US number formatter excludes "USD" from the output,
  // and only displays the dollar sign. Manually adding "USD"
  // to make the displayed price more explicit.
  return uppercaseCurrency === Currency.USD
    ? `${formattedPrice} ${uppercaseCurrency}`
    : formattedPrice
}

export const formatDate = (date: string): string => {
  const dateObj = new Date(date)
  return dateObj.toDateString()
}

/**
 * Preference is preferred name > full name > first name > user name
 *
 * @param {PublicUser} user
 * @return {string} Name to display
 */
export const displayName = (user?: PublicUser): string => {
  return user?.publicName || ''
}

/**
 * Pluralise and title case a string
 * @param input
 */
export const pluralCases = (
  input: string,
): { title: string; plural: string; titlePlural: string } => {
  const title = input.charAt(0).toUpperCase() + input.slice(1)
  const plural = pluralize(input)
  const titlePlural = pluralize(title)

  return {
    title,
    plural,
    titlePlural,
  }
}

export const daysToWeeks = (days?: number) => (days ? Math.ceil(days / 7) : 0)

/**
 * Get list of years including firstYear and lastYear
 * @param firstYear
 * @param lastYear
 */
export const getYearRange = (
  firstYear = 1932,
  lastYear = new Date().getFullYear(),
): Array<number> => {
  return Array(lastYear - firstYear + 1)
    .fill(0)
    .map((_, i) => i + firstYear)
}

/**
 * Check if this enrolment is treated as successful
 *
 * @param enrolment
 */
export const enrolmentIsSuccessful = (enrolment?: Enrolment) => {
  switch (enrolment?.status) {
    case EnrolmentStatus.SUBMITTED:
    case EnrolmentStatus.ACCEPTED:
      return true
    default:
      return false
  }
}
