import classNames from 'classnames'
import React, { forwardRef } from 'react'

/**
 * Button variants based on visual theme
 */
const THEME_VARIANTS = {
  primary:
    'text-white bg-purple-heart px-5 hover:bg-tuna hover:text-white hover:border-tuna',
  secondary:
    'bg-white border border-tuna px-5 hover:bg-tuna hover:text-white hover:border-tuna',
  tertiary: 'border border-transparent',
} as const

/**
 * Button size variants
 */
const SIZE_VARIANTS = {
  small: 'py-2',
  medium: 'py-3',
} as const

type ThemeVariant = keyof typeof THEME_VARIANTS
type SizeVariant = keyof typeof SIZE_VARIANTS

type BaseButtonProps = {
  /** Content to be rendered inside the button */
  children: React.ReactNode
  /** Additional CSS classes to apply */
  className?: string
  /** Button size variant */
  size?: SizeVariant
  /** Visual theme variant */
  theme?: ThemeVariant
  /** Whether the button is disabled */
  disabled?: boolean
  /** Optional click handler */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

type ButtonAsButton = BaseButtonProps & {
  href?: never
  type?: 'button' | 'submit' | 'reset'
} & Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    keyof BaseButtonProps | 'type'
  >

type ButtonAsAnchor = BaseButtonProps & {
  href: string
  type?: never
} & Omit<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    keyof BaseButtonProps | 'href'
  >

type ButtonProps = ButtonAsButton | ButtonAsAnchor

/**
 * A versatile Button component that can render as either a button or anchor element.
 * Supports different themes, sizes, and states while maintaining accessibility.
 *
 * @example
 * ```tsx
 * <Button theme="primary" size="medium" onClick={() => console.log('clicked')}>
 *   Click me
 * </Button>
 *
 * <Button href="/path" theme="secondary">
 *   Link Button
 * </Button>
 * ```
 */
const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      children,
      className,
      type = 'button',
      theme = 'primary',
      size = 'medium',
      href,
      disabled,
      onClick,
      ...props
    },
    ref,
  ) => {
    const sizeClass = theme !== 'tertiary' ? SIZE_VARIANTS[size] : ''

    const commonProps = {
      className: classNames(
        'inline-flex items-center font-medium',
        theme !== 'tertiary'
          ? 'justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-heart disabled:opacity-30 disabled:cursor-not-allowed'
          : '',
        sizeClass,
        THEME_VARIANTS[theme],
        className,
      ),
      onClick: disabled ? undefined : onClick,
    }

    if (href) {
      return (
        <a
          ref={ref as React.Ref<HTMLAnchorElement>}
          href={href}
          {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
          {...commonProps}
          aria-disabled={disabled}
        >
          {children}
        </a>
      )
    }

    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        type={type}
        disabled={disabled}
        {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        {...commonProps}
      >
        {children}
      </button>
    )
  },
)

// Set display name for better debugging
Button.displayName = 'Button'

export default Button
