import gql from 'graphql-tag'
import { PAGE } from './fragments/page'

export const GET_PAGE = gql`
  query page($slug: String!) {
    page: getPage(slug: $slug) {
      ...pageFields
    }
  }
  ${PAGE}
`

export const GET_MENU = gql`
  query menu {
    menu: getMenu {
      ...pageFields
    }
  }
  ${PAGE}
`
