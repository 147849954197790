type ContainerProps = {
  children: React.ReactNode
  className?: string
}

const Container = ({ children, className = '' }: ContainerProps) => (
  <div
    className={`w-full mx-auto px-4 md:px-10 lg:px-12 xl:px-20 ${className}`}
  >
    {children}
  </div>
)

export default Container
