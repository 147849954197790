import classNames from 'classnames'
import UserLink from 'components/UserLink/UserLink'
import { displayName } from 'lib/utils'
import Image from 'next/image'
import React from 'react'
import { TransparentizeHex } from 'styles/colors'
import { PublicUser } from 'types/user'

const avatarStyles = [
  'bg-avatar-red',
  'bg-avatar-orange',
  'bg-avatar-green',
  'bg-avatar-blue',
  'bg-avatar-purple',
  'avatar-greenLight',
]

interface AvatarProps {
  user: PublicUser
  nameVisible?: boolean
  nameLeft?: boolean
  size?: 'sm' | 'md' | 'xl'
  borderColour?: string
  hasLink?: boolean
}

const Avatar = ({
  user,
  nameVisible = true,
  nameLeft = false,
  size,
  borderColour,
  hasLink = false,
}: AvatarProps) => {
  const { id, initials } = user
  if (!id) {
    return null
  }

  const index = typeof id === 'number' ? id : id.charCodeAt(0)
  const styles = user.avatar?.downloadUrl
    ? ''
    : avatarStyles[index % avatarStyles.length]

  const Wrapper = ({ children }: { children: React.ReactNode }) =>
    hasLink ? <UserLink user={user}>{children}</UserLink> : <>{children}</>

  return (
    <Wrapper>
      <div
        className={classNames(
          'inline-flex items-center',
          nameLeft ? 'flex-row-reverse' : '',
        )}
      >
        <div
          className={classNames(
            'display-initials text-white rounded-full inline-flex justify-center items-center avatar overflow-hidden bg-purple-heart',
            size === 'sm'
              ? '!text-xs'
              : size === 'md'
                ? '!text-sm'
                : size === 'xl'
                  ? '!text-6xl'
                  : '',
            styles,
          )}
          style={
            borderColour
              ? {
                  borderColor: TransparentizeHex(borderColour, 0.1),
                  borderWidth: '2px',
                  boxShadow: `0 0 0 2px ${borderColour}`,
                  // borderColor: borderColour,
                  // borderWidth: '3px'
                }
              : {}
          }
        >
          {user.avatar?.downloadUrl ? (
            <Image
              src={user.avatar?.downloadUrl}
              width={160}
              height={160}
              alt=''
              className='object-cover'
              priority
            />
          ) : (
            <div className='text'>{initials}</div>
          )}
        </div>
        {nameVisible && (
          <div className='display-name text-sm mx-3'>{displayName(user)}</div>
        )}
      </div>
    </Wrapper>
  )
}

export default Avatar
