import gql from 'graphql-tag'

export const USER_AVATAR_FILE = gql`
  fragment avatarFileFields on UserProfileAvatarFile {
    id
    createdAt
    updatedAt
    filename
    status
    canDownload
    downloadUrl
  }
`

// Fields used by all users
export const PUBLIC_USER = gql`
  fragment publicUserFields on UserInterface {
    # Public non-writable fields
    id
    initials
    avatar {
      ...avatarFileFields
    }
    provider {
      id
      title
      colour
    }

    # Public writable fields
    publicName
    jobTitle
    aboutMe
  }
  ${USER_AVATAR_FILE}
`

// Fields used by private users only
export const USER = gql`
  fragment userFields on User {
    # Private non-writable fields
    name
    email
    isDomestic
    preferredCurrency

    # Private writable fields
    firstName
    preferredName
    lastName
    country
    phone
    dateOfBirth
    qualification
    qualificationProvider
    gender
    disability
    disabilityDetail
    countryOfCitizenship
    address
    iwi
    ethnicity
    residencyStatus
    visaExpiryDate
    isResidingInNz
    secondarySchool
    secondarySchoolLastYear
    secondarySchoolQualifications
    universityFirstYear
    organisation
    mainOccupation
    emergencyContactName
    emergencyContactPhone
    emergencyContactRelationship
    emergencyContactEmail

    # All public fields are also on private user
    ...publicUserFields
  }
  ${PUBLIC_USER}
`

// Fields used by next-auth session user (keep within 4096 bytes) or next-auth crashes
export const SESSION_USER = gql`
  fragment sessionUserFields on User {
    # Basic fields required for avatar.tsx
    # Note: avatar itself will need to be loaded client side
    id
    name
    email
    initials
    publicName
    preferredName
    firstName
    lastName
  }
`
