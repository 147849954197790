import React, {
  createContext,
  useContext,
  useRef,
  RefObject,
  ReactNode,
} from 'react'

interface StepsRefsContextType {
  commentsRef: RefObject<HTMLDivElement>
  contentRef: RefObject<HTMLDivElement>
}

const StepsRefsContext = createContext<StepsRefsContextType | null>(null)

export const useStepsRefs = (): StepsRefsContextType => {
  const context = useContext(StepsRefsContext)
  if (!context) {
    throw new Error('useStepsRefs must be used within a StepsRefsProvider')
  }
  return context
}

interface StepsRefsProviderProps {
  children: ReactNode
}

export const StepsRefsProvider: React.FC<StepsRefsProviderProps> = ({
  children,
}) => {
  const commentsRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  return (
    <StepsRefsContext.Provider value={{ commentsRef, contentRef }}>
      {children}
    </StepsRefsContext.Provider>
  )
}
