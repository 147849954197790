import { useQuery } from '@apollo/client'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import Avatar from 'components/Avatar/Avatar'
import Button from 'components/Button'
import Container from 'components/Container'
import { useSidebar } from 'context/SidebarProvider/SidebarProvider'
import { motion } from 'framer-motion'
import { GET_USER } from 'gql/users'
import { signOut, useSession } from 'next-auth/client'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import { menuItemClasses, menuTransition } from 'styles/menu'
import { User } from 'types/user'
import HamburgerIcon from '../svg/hamburger.svg'
import LearningPlatformLogo from '../svg/learning-platform.svg'
import NotificationsMenu from './NotificationsMenu/NotificationsMenu'

type HeaderProps = {
  showLogin?: boolean
  header?: React.ReactNode
}

const Header = ({ showLogin, header }: HeaderProps) => {
  const { t } = useTranslation()

  const [session, loading] = useSession()
  const { isSidebarOpen, openSidebar } = useSidebar()

  const user = session?.user || null

  const { data: userData } = useQuery<{ me: User }>(GET_USER, {
    skip: !user,
  })

  if (loading) {
    return <p>Loading...</p>
  }

  // Update avatar of user record
  let mergedUser: User | null = null
  if (session && user && userData?.me) {
    mergedUser = {
      ...user,
      avatar: userData.me.avatar,
    }
  } else if (session && user) {
    mergedUser = { ...user }
  }

  const userNavigation = mergedUser
    ? [
        { name: t('Public profile'), href: '/user/me' },
        { name: t('Personal details'), href: '/user/me/personal-details' },
        { name: t('Reset password'), href: '/reset-password' },
      ]
    : showLogin
      ? [
          { name: t('Login'), href: '/login' },
          { name: t('Register'), href: '/register' },
        ]
      : []

  return (
    <header className='sticky backdrop-filter backdrop-blur-lg bg-white bg-opacity-70 top-0 border-b mb-8 md:mb-16 z-20'>
      <Disclosure as='nav'>
        {({ open }) => (
          <>
            <Container>
              <div className='w-full flex justify-between h-20 items-center'>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: !isSidebarOpen ? 1 : 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {!isSidebarOpen && (
                    <Link href='/'>
                      <a>
                        <LearningPlatformLogo />
                      </a>
                    </Link>
                  )}
                </motion.div>
                {header}
                <div className='flex items-center'>
                  {mergedUser && <NotificationsMenu />}
                  <Menu as='div' className='mr-3 relative'>
                    {({ open }) => (
                      <>
                        <div>
                          {mergedUser && (
                            <Menu.Button className='bg-purple-heart rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-heart'>
                              <span className='sr-only'>Open user menu</span>
                              <Avatar user={mergedUser} nameVisible={false} />
                            </Menu.Button>
                          )}
                          {!mergedUser && showLogin && (
                            <div className='md:flex gap-3 hidden'>
                              <Button
                                size='small'
                                theme='secondary'
                                href={'/login'}
                              >
                                Login
                              </Button>
                              <Button size='small' href={'/register'}>
                                Get started today
                              </Button>
                            </div>
                          )}
                        </div>
                        <Transition show={open} {...menuTransition}>
                          <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10'>
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <Link href={item.href}>
                                    <a className={menuItemClasses(active)}>
                                      {item.name}
                                    </a>
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                            <Menu.Item>
                              <a
                                className={menuItemClasses(false)}
                                onClick={() => signOut()}
                              >
                                {t('Sign out')}
                              </a>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                  <button className='md:hidden' onClick={() => openSidebar()}>
                    <HamburgerIcon />
                  </button>
                </div>
              </div>
            </Container>
          </>
        )}
      </Disclosure>
    </header>
  )
}

export default Header
