import gql from 'graphql-tag'

export const COURSE_PROVIDER = gql`
  fragment courseProviderFields on CourseProvider {
    id
    title
    slug
    colour
    imageUrl
    description
    contentJson
  }
`
