import gql from 'graphql-tag'

export const PAGE = gql`
  fragment pageFields on Page {
    id
    title
    slug
    status
    contentJson
  }
`
