import {
  PUBLIC_USER,
  SESSION_USER,
  USER,
  USER_AVATAR_FILE,
} from 'gql/fragments/user'
import gql from 'graphql-tag'
import { SocialProvider, User, UserInput } from 'types/user'

export const GET_USER = gql`
  query me {
    me {
      ...userFields
    }
  }
  ${USER}
`

export const GET_USER_BY_ID = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      ...publicUserFields
    }
  }
  ${PUBLIC_USER}
`

export const CREATE_SESSION = gql`
  mutation login($email: String!, $password: String!) {
    session: createUserSession(email: $email, password: $password) {
      token
      expires
      refreshExpires
      user {
        ...sessionUserFields
      }
    }
  }
  ${SESSION_USER}
`

export type CreateSocialSessionArgs = {
  provider: SocialProvider
  token: string
  name?: string | null
}

export const CREATE_SOCIAL_SESSION = gql`
  mutation socialLogin($provider: SocialProvider!, $token: String!, $name: String) {
    session: createSocialSession(provider: $provider, token: $token, name: $name) {
      token
      expires
      refreshExpires
      user {
        ...sessionUserFields
      }
    }
  }
  ${SESSION_USER}
`

export const CREATE_USER = gql`
  mutation register(
    $first_name: String!
    $last_name: String!
    $preferred_name: String
    $phone: String
    $email: String!
    $country: String!
    $password: String!
    $password_confirmation: String!
  ) {
    registration: registerUser(
      user: {
        first_name: $first_name
        last_name: $last_name
        preferred_name: $preferred_name
        phone: $phone
        email: $email
        country: $country
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
      message
      session {
        token
        expires
        refreshExpires
        user {
          ...sessionUserFields
        }
      }
      errors
    }
  }
  ${SESSION_USER}
`

export const REFRESH_TOKEN = gql`
  mutation refreshUserSession {
    refreshUserSession {
      token
      expires
      refreshExpires
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $student_id: String!
    $token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    resetPassword(
      student_id: $student_id
      token: $token
      password: $password
      password_confirmation: $password_confirmation
    ) {
      status
      message
    }
  }
`

export const GET_INVITE_VALIDITY = gql`
  query getInvitationValidity($token: String!) {
    getInvitationValidity(token: $token)
  }
`

export const ACTIVATE_ACCOUNT = gql`
  mutation activateAccount($token: String!, $password: String!, $password_confirmation: String!) {
    activateAccount(
      token: $token
      password: $password
      password_confirmation: $password_confirmation
    ) {
      isSuccess
      message
      studentId
    }
  }
`

export const RESEND_INVITE = gql`
  mutation resendInvitation($token: String!, $email: String!) {
    resendInvitation(token: $token, email: $email)
  }
`

export type UpdateUserProfileResult = {
  profile: User
}
export type UpdateUserProfileArgs = {
  fields: UserInput
}
export const UPDATE_USER = gql`
  mutation updateUserProfile($fields: UserInput!) {
    profile: updateUserProfile(fields: $fields) {
      ...userFields
    }
  }
  ${USER}
`

export const GENERATE_USER_AVATAR_UPLOAD_TOKEN = gql`
  mutation generateUserProfileAvatarUploadToken($fileName: String!, $contentType: String!) {
    generateUserProfileAvatarUploadToken(fileName: $fileName, contentType: $contentType) {
      method
      url
      fields
      headers
      submissionFile {
        ...avatarFileFields
      }
    }
  }
  ${USER_AVATAR_FILE}
`

export const DELETE_USER_AVATAR_FILE = gql`
  mutation deleteUserProfileAvatarFile($fileId: ID!) {
    deleteUserProfileAvatarFile(fileId: $fileId)
  }
`

export const COMPLETE_USER_AVATAR_FILE = gql`
  mutation completeUserProfileAvatarFile($fileId: ID!) {
    completeUserProfileAvatarFile(fileId: $fileId) {
      ...avatarFileFields
    }
  }
  ${USER_AVATAR_FILE}
`
