import classNames from 'classnames'
import Avatar from 'components/Avatar/Avatar'
import dayjs from 'dayjs'
import { CommentNotification } from 'types/user'

import { displayName } from 'lib/utils'

const CommentNotificationView = ({
  notification,
}: { notification: CommentNotification }) => {
  const comment = notification.comment
  const step = comment.courseStep
  const userName = displayName(comment.user)
  const stepName = step.title

  return (
    <div
      className={classNames(
        'px-4 py-4 text-sm flex space-x-2',
        notification.isRead ? 'bg-gray-50' : '',
      )}
    >
      <div className='flex-0 w-8'>
        <Avatar user={comment.user} nameVisible={false} size='sm' />
      </div>
      <div
        className={classNames(
          'flex-1 leading-snug',
          notification.isRead ? 'opacity-60 text-gray-500' : 'font-semibold',
        )}
      >
        {userName} replied to a comment in{' '}
        <span className='text-purple-heart'>
          {step.reference} {stepName}
        </span>
      </div>
      <div className='flex-0 text-right w-6 text-gray-400'>
        {dayjs(comment.createdAt).locale('shortNames').fromNow()}
      </div>
    </div>
  )
}

export default CommentNotificationView
