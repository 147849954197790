import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import { Icon, type IconName } from 'components/Icon/Icon'
import { useSidebar } from 'context/SidebarProvider/SidebarProvider'
import { GET_MENU } from 'gql/page'
import { GET_COURSE_PROVIDERS } from 'gql/provider'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import type { CourseProvider, Page } from 'types/index'
import CloseIcon from '../../svg/close.svg'
import LearningPlatformLogo from '../../svg/learning-platform.svg'

type SidebarProps = {
  children: React.ReactNode
  alternateMenu?: React.ReactNode
}

type NavigationItem = {
  name: string
  href: string
  icon?: IconName
}

export const Sidebar = ({ children, alternateMenu }: SidebarProps) => {
  const { t } = useTranslation()
  const { data: menuData } = useQuery<{ menu: Array<Page> }>(GET_MENU)
  const [isClient, setIsClient] = useState(false) // Track if we're on the client
  const { isSidebarOpen, closeSidebar } = useSidebar()
  const router = useRouter()

  // get course providers (GET_COURSE_PROVIDERS)
  const { data: providersData } = useQuery<{
    providers: Array<CourseProvider>
  }>(GET_COURSE_PROVIDERS)

  // Use effect to set isClient to true after component mounts
  useEffect(() => {
    setIsClient(true)
  }, [])

  const navigation: NavigationItem[] = [
    {
      name: t('Explore Courses'),
      href: '/',
      icon: 'folder',
    },
    {
      name: t('My Courses'),
      href: '/dashboard',
      icon: 'folder',
    },
    ...(menuData?.menu || []).map((page) => ({
      name: page.title,
      href: `/page/${page.slug}`,
    })),
  ]

  return (
    <div className='flex min-h-screen text-tuna'>
      {/* Sidebar */}
      <div
        className={classNames(
          'h-screen fixed top-0 left-0 w-full md:w-80 bg-[#F5F3FB] transform transition-transform duration-300 z-50',
          {
            'translate-x-0': isSidebarOpen, // Opened state (for both desktop and mobile)
            '-translate-x-full md:-translate-x-80': !isSidebarOpen, // Hidden state (for mobile)
          },
        )}
      >
        <div className='flex bg-white md:bg-transparent justify-between items-center px-3 md:px-6 h-20 border-b border-mischka'>
          <Link href='/'>
            <a>
              <LearningPlatformLogo />
            </a>
          </Link>
          <button className='md:hidden' onClick={() => closeSidebar()}>
            <CloseIcon />
          </button>
        </div>

        {/* Alternate Menu */}
        {alternateMenu ? (
          <div>{alternateMenu}</div>
        ) : (
          <ul className='py-3 px-6 flex flex-col gap-2'>
            {navigation.map((item) => (
              <li key={item.href}>
                <Link href={item.href}>
                  <a
                    className={classNames(
                      isClient && item.href === router.asPath
                        ? 'bg-white text-purple-heart'
                        : '',
                      'flex items-center font-semibold py-2 pl-2 pr-3 rounded-md',
                    )}
                  >
                    {item?.icon && (
                      <Icon name={item.icon} className='flex mr-2' />
                    )}
                    <div>{item.name}</div>
                  </a>
                </Link>
              </li>
            ))}
            <li>
              <div className='w-full flex items-center font-semibold py-2 pl-2 pr-3'>
                <Icon name='university' className='flex mr-2' />
                <div>{t('Providers')}</div>
              </div>
              <ul className='py-3 px-6 flex flex-col gap-2'>
                {providersData?.providers.map((item) => (
                  <li key={item.id}>
                    <Link href={`/providers/${item.slug}`}>
                      <a
                        className={`flex items-center font-semibold py-2 pl-4 pr-3 rounded-md ${isClient && `/providers/${item.slug}` === router.asPath ? 'bg-white text-purple-heart' : ''}`}
                      >
                        <div>{item.title}</div>
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        )}
      </div>

      {/* Main Content */}
      <div
        className={classNames(
          'flex-1 transition-all duration-300',
          {
            'md:ml-0': !isSidebarOpen, // No margin when sidebar is closed
            'md:ml-80': isSidebarOpen, // Shift content when sidebar is open (desktop)
          },
          'ml-0', // Always margin on desktop
        )}
      >
        {children}
      </div>
    </div>
  )
}
